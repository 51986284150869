@media screen and (min-width: 768px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 3);
  }

  /* .parent-container img{
        height: 40vh;
    } */
}

@media screen and (max-width: 575px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 2);
  }
}

@media screen and (max-width: 428px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    display: block;
    margin-right: 0;
    flex: 0 0 calc(100% / 1);
  }
}


.carousel-inner {
  padding: 1em;
}

.card {
  margin: 0 1.6em;
  border-radius: 10px;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  font-size: 0.9em;
}

.carousel-control-prev,
.carousel-control-next {
  width: 6vh;
  height: 6vh;
  background-color: #e1e1e1;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 0.8;
}
