body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.scrollable-dropdown {
  max-height: 380px; /* Adjust the height as needed */
  overflow-y: auto;
  margin-top: 0px !important;
}
.newarea{
 width: 100%;
 margin-top: 90px;

}
.mapimg{
  width: 60%;
height: 400px;
margin-top: 50px;

}
.arealist{
  list-style: none;
  padding: 0px;
}
.headingarea{
  cursor: pointer;
}

.navbar-nav .nav-link {
  color: #9f9c9c ;
}
.dropend .dropdown-toggle {
  color: rgb(50, 48, 48) !important;
  margin-left: 1em;
}
.dropdown-item:hover {
  background-color: rgb(172, 169, 169);
  color: #e9e4e4;
}
.dropdown .dropdown-menu {
  display: none;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
  
  
}
