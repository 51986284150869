
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap");
:root {
    --bs-success: #30a18b;
}

a {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4 {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
}

h1 {
    font-size: 2.3em !important;
}

h2 {
    font-size: 1.8em !important;
    text-transform: capitalize;
}

h3 {
    font-size: 1.5em !important;
}

h4 {
    font-size: 1.2em !important;
}

h5 {
    font-size: 1em !important;
}

h6 {
    font-size: 1em !important;
}

p {
    font-family: sans-serif;
    font-size: 1em !important;
    color: gray;
    text-align: justify !important;
}

li,
label,
::placeholder {
    font-family: sans-serif;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2.5em !important;
    }
    h2 {
        font-size: 1.8em !important;
    }
    h3 {
        font-size: 1.5em !important;
    }
    h4 {
        font-size: 1.4em !important;
    }
    h5 {
        font-size: 1em !important;
    }
    h6 {
        font-size: 0.9em !important;
    }
    p {
        font-size: 0.9em !important;
    }
}

@media (max-width: 576px) {
    h1 {
        font-size: 2em !important;
    }
    h2 {
        font-size: 1.6em !important;
    }
    h3 {
        font-size: 1.3em !important;
    }
    h4 {
        font-size: 1.1em !important;
    }
    h5 {
        font-size: 1em !important;
    }
    h6 {
        font-size: 0.8em !important;
    }
    p {
        font-size: 0.8em !important;
    }
}

@media (max-width: 425px) {
    h1 {
        font-size: 1.6em !important;
    }
    h2 {
        font-size: 1.28em !important;
    }
    h3 {
        font-size: 1.024em !important;
    }
    h4 {
        font-size: 0.8192em !important;
    }
    h5 {
        font-size: 0.6554em !important;
    }
    h6 {
        font-size: 0.5243em !important;
    }
    p {
        font-size: 0.8192em !important;
    }
}

.bg-success,
.btn-success {
    background-color: var(--bs-success) !important;
    outline: none;
    border: none;
}

.text-success {
    color: var(--bs-success) !important;
}

html,
body {
    overflow-x: hidden;
}

body {
    background-color: #eeeaea;
}

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 99;
}

.btn {
    font-weight: 600;
    transition: 0.5s;
}

.btn-square {
    width: 32px;
    height: 32px;
}

.btn-sm-square {
    width: 34px;
    height: 34px;
}

.btn-md-square {
    width: 44px;
    height: 44px;
}

.btn-lg-square {
    width: 56px;
    height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50%;
}

.btn.btn-primary {
    box-shadow: inset 0 0 0 0 var(--bs-success);
    font-size: 20px !important;
    padding: 9px 18px;
}

.btn.btn-primary:hover {
    box-shadow: inset 300px 0 0 0 var(--bs-light) !important;
    color: var(--bs-success) !important;
}

.btn.btn-light {
    box-shadow: inset 0 0 0 0 var(--bs-success);
}

.btn.btn-light:hover {
    box-shadow: inset 300px 0 0 0 var(--bs-success);
    color: var(--bs-light) !important;
}

.btn-hover {
    transition: 0.5s;
}

.btn-hover:hover {
    color: var(--bs-secondary) !important;
}

.section-title {
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    color: var(--bs-success);
}

.main-nav-bar {
    position: fixed;
    top: 45px;
    z-index: 999;
}

.main-top-bar {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: var(--bs-success) !important;
}

@media(max-width: 991px){
    .main-top-bar{
        display: block !important;
    }
}

.contact-page {
    margin-top: 10rem;
}

@media (max-width: 991px) {
    .main-nav-bar {
        position: fixed;
        top: 0;
        z-index: 999;
    }
    .contact-page {
        margin-top: 5rem;
    }
}

.fixed-top .container {
    transition: 0.5s;
}

.topbar {
    padding: 2px 10px 2px 20px;
    background: var(--bs-success) !important;
}

.topbar a,
.topbar a i {
    transition: 0.5s;
}

.topbar a:hover,
.topbar a i:hover {
    color: var(--bs-secondary) !important;
}

.navbar-light .navbar-nav .nav-link {
    font-family: "Roboto", sans-serif;
    position: relative;
    padding: 35px 15px;
    color: var(--bs-white) !important;
    font-size: 17px;
    font-weight: 400;
    outline: none;
    transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 20px 15px;
    color: var(--bs-dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: var(--bs-white) !important;
}

.navbar-light .navbar-brand h1 {
    color: var(--bs-white);
}

.sticky-top.navbar-light .navbar-brand h1 {
    color: var(--bs-success);
}

.navbar-light .navbar-brand img {
    max-height: 60px;
    transition: 0.5s;
}

.sticky-top.navbar-light .navbar-brand img {
    max-height: 45px;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (min-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        border-radius: 10px;
        transition: 0.5s;
        opacity: 0;
    }
}

.dropdown .dropdown-menu a:hover {
    background: var(--bs-success);
    color: var(--bs-white);
}

.navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light);
    transition: 0.5s;
    opacity: 1;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-light {
        position: relative;
        background: var(--bs-white);
    }
    .navbar-light .navbar-brand h1 {
        color: var(--bs-success);
    }
    .navbar.navbar-expand-lg .navbar-toggler {
        padding: 10px 20px;
        border: 1px solid var(--bs-success);
        color: var(--bs-success);
    }
    .navbar-light .navbar-collapse {
        margin-top: 15px;
        border-top: 1px solid #dddddd;
    }
    .navbar-light .navbar-nav .nav-link,
    .sticky-top.navbar-light .navbar-nav .nav-link {
        padding: 10px 0;
        color: var(--bs-white) !important;
    }
    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link.active {
        color: var(--bs-success) !important;
    }
    .navbar-light .navbar-brand img {
        max-height: 45px;
    }
}

@media (min-width: 992px) {
    .navbar-light {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        z-index: 999;
    }
    .sticky-top.navbar-light {
        position: fixed;
        background: var(--bs-light);
    }
    .navbar-light .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0;
        bottom: -1px;
        left: 0;
        background: var(--bs-success);
        transition: 0.5s;
        z-index: -1;
    }
    .navbar-light .navbar-nav .nav-link:hover::before,
    .navbar-light .navbar-nav .nav-link.active::before {
        height: calc(100% + 1px);
        left: 1px;
    }
    .navbar-light .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }
}

.ts-heading {
    margin-top: 95px;
}

@media (max-width: 991px) {
    .ts-heading {
        margin-top: 0px;
    }
    .CTAButton {
        position: absolute;
        top: 4rem;
        right: 5.5rem;
    }
}

.ts-bg-image {
    background-image: url("https://via.placeholder.com/1200x400");
    background-repeat: no-repeat;
    background-size: cover;
    height: 52vh;
}

.ts-box-shadow {
    background-color: rgba(0, 0, 0, 0.6);
    width: fit-content;
    margin: 0 auto;
}

.ts-hr-line {
    width: 100px;
    height: 5px;
    background-color: black;
    margin: 0 auto;
}

.tc-home-banner {
    background-color: var(--bs-dark);
}

.tc-text-color {
    color: var(--bs-success);
}

.pc-home-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/banners/24-Hour Mobile Tyre Fitting For Emergency.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 83vh;
}

.pc-home-banner-icon i {
    color: #fff;
    font-size: 40px;
}

.pc-bi-box {
    display: flex;
    gap: 0.5rem;
}

.pc-bannerNumbers {
    display: flex;
    justify-content: space-between;
}

blockquote img {
    border-radius: 50%;
    margin-top: 10px;
}

#slider-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}

#testimonials {
    position: relative;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 1000px;
    height: 450px;
}

figure {
    position: absolute;
    width: 400%;
    left: 0;
    animation: 20s slider infinite;
    -webkit-animation: 20s slider infinite;
    border-top: 1px solid #c2c1c2;
    margin: 0;
}

blockquote {
    width: 25%;
    margin: 0;
    float: left;
    padding-bottom: 1em;
}

@-webkit-keyframes slider {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(0%);
    }
    33% {
        transform: translateX(-25%);
    }
    58% {
        transform: translateX(-25%);
    }
    66% {
        transform: translateX(-50%);
    }
    91% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-75%);
    }
}

@keyframes slider {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(0%);
    }
    33% {
        transform: translateX(-25%);
    }
    58% {
        transform: translateX(-25%);
    }
    66% {
        transform: translateX(-50%);
    }
    91% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-75%);
    }
}

footer {
    padding: 1em;
    float: right;
    margin-right: 2em;
}

@media (min-width: 750px) {
    #testimonials {
        height: 150px;
    }
}

.dark-color {
    background-color: #212529 !important;
}

.process-box {
    position: relative;
    text-align: center;
}

.process-box .process-number,
.process-box .process-icon,
.process-box {
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
}

.process-box .process-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin-bottom: 13px;
    z-index: 2;
    position: relative;
    background-color: var(--white-color);
    color: var(--title-color);
    font-size: 28px;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    margin-bottom: 25px;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
}

.process-box .process-icon i:before {
    line-height: inherit;
}

.process-box .process-number {
    font-size: 20px;
    font-weight: 700;
    background-color: var(--bs-success);
    color: white;
    z-index: 1;
    width: 67px;
    height: 67px;
    line-height: 67px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-top: 10px;
}

.process-box .process-number:before {
    content: "";
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    z-index: -1;
    border-radius: inherit;
}

.process-box .process-text {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.process-box .process-border {
    position: absolute;
    right: -130px;
    top: 89px;
}

.process-box:nth-child(even) .process-border {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    top: 15px;
}

.process-box:last-child .process-border {
    display: none !important;
}

.fa-house,
.linkStyle {
    text-decoration: none;
    color: var(--bs-success);
}

.parent-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #30a18ab7;
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.8s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay h4 {
    color: black;
}

.overlay p {
    color: white;
}

.parent-container:hover .overlay {
    opacity: 1;
}

.img-wrapper img {
    width: 100%;
    max-height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.headline,
.headline span,
.headline h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clipped {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

@media screen and (max-width: 650px) {
    .headline {
        left: 20px;
    }
    .clipped {
        clip-path: none;
        max-height: 300px !important;
    }
}

.headerStyle {
    margin-top: 142px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 8rem;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannerStyle,
.mtf,
.mtr,
.tr,
.ft,
.mb,
.bt,
.pwt,
.js,
.lwr {
    margin-top: 142px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 8rem;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mtf {
    background-image: url("../images/banners/Mobile tyre fitting.png");
}

.mtr {
    background-image: url("../images/banners/Tyre repair.jpg");
}

.areas{
    background-image: url("../images/banners/new-banner3.jpg");
}
.tyrereplacment{
    background-image: url("../images/banners/tyre replacement.png");

}

.tr {
    /* background-image: url("../images/banners/tyre replacement.png"); */
    /* background: scroll; */
    width: 100%;
    /* height: 75%; */
    background-repeat: no-repeat;
    
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: fill;
    overflow: hidden;
    position: relative;
    isolation: isolate;
   
}

.ft {
    background-image: url("../images/banners/Flat tyre.png");
}

.mb {
    background-image: url("../images/banners/Breakdown recovery.png");
}

.bt {
    background-image: url("../images/banners/bursttyre.png");
}

.pwt {
    background-image: url("../images/banners/Part worn tyres.png");
}

.js {
    background-image: url("../images/banners/Jumpstart (2).png");
}

.lwr {
    background-image: url("../images/banners/lockingwheel.png");
}

@media (max-width: 991px) {
    .headerStyle,
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: 0;
    }
    .order-small-1 {
        order: -1;
    }
   
}

.footer-link {
    text-decoration: none;
    color: white;
}

.footer-link:hover {
    color: var(--bs-success);
}

.about-circle {
    position: absolute;
    width: 80px;
    height: 80px;
    background: var(--bs-success);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.about-box {
    width: 90%;
    padding: 20px;
    margin-left: 50px !important;
}

.about-box p {
    width: 70%;
    text-align: justify;
    margin: 0 auto;
}

.card-boxes {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.card-boxes>div {
    width: 30px;
    height: 30px;
    border: 2px solid var(--bs-success);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .small-screen-form {
        display: flex;
        gap: 2px;
    }
    .areas{
    background-image: url("../images/banners/mobile-new30protyrefitterss.jpg");
}
}

.background-image {
    background: #18bd9d;
    padding: 20px;
    border-radius: 10px;
}

.top-header-icons small {
    font-size: 12px;
}

.cards-image img {
    height: 40vh !important;
}

@media (max-width: 575px) {
    .first-section-margin {
        margin-top: -140px !important;
    }
    .parent-container {
        text-align: center;
    }
    .overlay {
        width: 90%;
        position: absolute;
        top: 0;
        left: 5%;
    }
}

@media (max-width: 477px) {
    .overlay {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.cards-image img {
    height: 45vh;
}

@media (max-width: 768px) {
    .cards-image img {
        height: 28vh !important;
    }
    .pc-home-banner {
        background-size: contain;
        height: 56vh;
    }
    .map-image,
    .pc-bannerNumbers {
        display: none;
    }
    .navbar-toggler {
        margin-left: 200px !important;
    }
}

@media (max-width: 610px) {
    .navbar-toggler {
        margin-left: 0 !important;
    }
}

.hover-logo img {
    display: none;
}

@media (max-width: 575px) {
    .hover-logo img {
        display: block;
        position: absolute;
        left: 10px;
        width: 18% !important;
    }
    .pc-home-banner {
        background-size: cover;
        height: 75vh;
    }
    .ts-logo-footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ts-logo-footer img {
        width: 45% !important;
    }
    .cards-image img {
        height: 75vh;
    }
    .headerStyle {
        background-size: contain !important;
        margin-top: 6% !important;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-bottom: -30px;
    }
}

.para-width {
    width: 90% !important;
}

.single-banner-image {
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .about-row {
        padding: 10px;
        gap: 4px;
    }
    .about-row>div {
        border-radius: 10px;
    }
    .headerStyle {
        background-size: cover;
        height: 48vh;
        margin-top: 55px;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        background-size: cover;
        height: 48vh;
    }
}

.ts-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ts-logo img {
    width: 45%;
    height: auto;
    margin-top: 15px;
}

.ts-logo-footer img {
    width: 75%;
}

@media (max-width: 768px) {
    .ts-logo-container {
        display: flex;
        justify-content: space-evenly;
    }
    .ts-logo-mobile img {
        width: 25%;
        height: auto;
        margin-top: 0;
    }
    .topbar {
        display: none;
    }
    .overlay {
        font-size: 12px;
    }
}

@media (max-width: 429px) {
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: 98px !important;
        margin-bottom: 1rem !important;
    }
    .about-header {
        margin-top: -30px !important;
        margin-bottom: -4rem;
    }
}

@media (max-width: 425px) {
    .ts-logo-mobile img {
        width: 35%;
        height: auto;
        margin-top: 8px;
    }
    .ts-logo-footer img {
        width: 36%;
    }
    .bannaer-logo {
        width: 15% !important;
    }
    .headerStyle {
        margin-top: 10px !important;
        margin-bottom: -30px;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: -28px;
        margin-bottom: -70px;
    }
}

@media (max-width: 414px) {
    .headerStyle {
        margin-top: 2% !important;
        margin-bottom: -30px;
    }
}

@media (max-width: 391px) {
    .pc-home-banner {
        height: 62vh !important;
    }
    .headerStyle,
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: -79px !important;
        margin-bottom: -8rem;
    }
}

@media (max-width: 390px) {
    .headerStyle {
        margin-top: -4% !important;
        margin-bottom: -92px;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: 98px !important;
        /* margin-bottom: -100px; */
    }
    .hover-logo img {
        display: block;
        position: absolute;
        left: 5px;
        margin-top: -18px !important;
        width: 20% !important;
    }
}

@media (max-width: 360px) {
    .headerStyle {
        margin-top: -1% !important;
        margin-bottom: -45px;
    }
}

@media (max-width: 330px) {
    .parent-container img {
        width: 100%;
        height: 38vh;
    }
    .headerStyle {
        margin-top: -2% !important;
        margin-bottom: -45px;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        margin-top: -45px;
    }
}

.btn.dropdown-toggle.text-white.px-0 {
    border: none;
}

.new-dropdown-menu {
    width: 500px !important;
    margin-left: -200px !important;
}

.tyre-icons {
    width: 38px;
}

.img-fix {
    height: 50vh;
    width: 75%;
}

.mobile-navbar {
    display: none;
}

.desktop-navbar {
    display: block;
}

@media (max-width: 991px) {
    .desktop-navbar {
        display: none;
    }
    .mobile-navbar {
        display: block;
    }
    .site_logo {
        max-width: 700px;
    }
}

@media (max-width: 658px) {
    .img-fix {
        width: 100%;
    }
    .headerStyle {
        height: 45vh;
    }
    .site_logo {
        max-width: 400px;
    }
}

.top-header-icons>small {
    margin-left: 8px;
}

@media (max-width: 1024px) {
    .top-header-icons>small {
        font-size: 11px;
    }
    .bannerStyle,
    .mtf,
    .mtr,
    .tr,
    .ft,
    .mb,
    .bt,
    .pwt,
    .js,
    .lwr {
        height: 60vh;
    }
}

.home-carousel img {
    height: 70vh;
}

.hero__section {
    margin-top: 110px;
}

@media (max-width: 1024px) {
    .hero__section {
        margin-top: 122px;
    }
}

@media (max-width: 768px) {
    .hero__section {
        margin-top: 0;
    }
    .home-carousel img {
        height: 55vh;
    }
    .home-carousel h1 {
        position: absolute;
        top: 10px;
    }
     .mapimg{
  width: 60%;
height: 200px !important;
margin-top: 50px;

}
}

@media (max-width: 575px) {
    .hero__section {
        margin-top: 50px;
    }
    .home-carousel img {
        height: 40vh;
    }
    .home-carousel h1 {
        position: absolute;
        top: 30px;
    }
     .mapimg{
  width: 60%;
height: 200px !important;
margin-top: 50px;

}
}

@media (max-width: 429px) {
    .home-carousel img {
        height: 35vh;
        object-fit: cover;
    }
    .home-carousel h1 {
        font-size: 15px !important;
        position: absolute;
    }
    .img-fluid {
        width: 87%;
    }
    .parent-container img {
        height: 35vh;
    }
    .overlay {
        width: 87%;
        margin-left: 7%;
    }
    .blog2img{
    width: 500px !important;
    margin-top: 50px !important;
  }
  .mapimg{
  width: 60%;
height: 200px !important;
margin-top: 50px;

}
}
    .blog2img{
    width: 100%;
    margin-top: 50px !important;
    /* height: 500px; */
  }
.dropdown-hover .harea-menu {
    display: none;
    
}

.londonbut{
    width: 85px !important;
    
}
.dropdown-hover:hover .harea-menu {
    display: block;
    position: absolute;
    /* left: 100%; */
    margin-left: 155px;
    /* margin-top: 15px !important;    */
    z-index: 1000;
    background-color: #dddddd;
    width: auto !important;
}

.ptf-hero {
    margin-top: 140px;
    background-image: url('../images/banners/bannerone.jpg');
    background-repeat: no-repeat;
    padding: 6.5rem 0;
}

@media(max-width: 768px) {
    .ptf-hero {
        margin-top: 45px !important;
    }
}

.whatsapp__icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.whatsapp__icon i {
    font-size: 52px;
    color: #25D366;
}

.fix-img {
    height: 70vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    overflow: hidden;
    position: relative;
    isolation: isolate;
    flex-wrap: wrap;
    text-shadow: 1px -2px 10px rgba(0,0,0,0.6);
}

@media(max-width: 576px){
    .fix-img{
        background-image: url('../images/banners/banneronesmall.jpg');
        height: auto;
        background-position: center;
    }
}